import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import DPlayer from "dplayer";
import util from "@/libs/util";
import { uploadFile, getUserFileListData, renameUserFile, trashFile, collectFile } from "@/api/index";
import fileCategory from "@/views/app-toB/file/fileCategory";
export default {
  name: "materialCenter",
  components: {
    fileCategory
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    mode: {
      validator(value) {
        return util.oneOf(value, ["图片", "视频"]);
      },
      type: String,
      default: "图片"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showMenus: {
      type: Boolean,
      default: true
    },
    showUpload: {
      type: Boolean,
      default: true
    },
    showInput: {
      type: Boolean,
      default: true
    },
    maxlength: {
      type: Number,
      default: 250
    },
    icon: {
      type: String,
      default: "ios-cloud-upload-outline"
    },
    maxSize: {
      type: Number,
      default: 5
    },
    acceptImg: {
      type: String,
      default: ".jpg, .jpeg, .png, .gif"
    },
    acceptVideo: {
      type: String,
      default: ".mp4, .wmv, .avi, .mov, .flv, .mkv"
    }
  },
  computed: {
    format() {
      if (this.accept) {
        let format = [];
        this.accept.split(",").forEach(e => {
          format.push(e.replace(".", "").replace(" ", ""));
        });
        return format;
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      uploadData: {},
      accessToken: {},
      accept: "",
      urlMode: false,
      modalVisible: this.value,
      loading: false,
      dataLoading: false,
      uploadFileUrl: uploadFile,
      searchForm: {
        title: "",
        type: "image",
        pageNumber: 1,
        pageSize: 10,
        categoryId: "0",
        isCollect: null
      },
      total: 0,
      data: [],
      selectList: [],
      form: {
        url: ""
      },
      ruleValidate: {
        url: [{
          required: true,
          message: "请输入合法的URL链接",
          type: "url"
        }]
      },
      dp: null,
      videoVisible: false,
      videoTitle: "",
      videoId: "dplayer"
    };
  },
  methods: {
    init() {
      this.accessToken = {
        accessToken: this.getStore("accessToken")
      };
      this.changeAccept();
      this.getDataList();
    },
    selectCategory(v) {
      if (v.id == "-1") {
        // 收藏夹
        this.searchForm.categoryId = "";
        this.searchForm.isCollect = true;
        this.getDataList();
      } else {
        this.searchForm.isCollect = null;
        this.searchForm.categoryId = v.id;
        this.uploadData = {
          categoryId: v.id
        };
        this.currCate = v.title;
        this.getDataList();
      }
    },
    changeAccept() {
      if (this.mode == "图片") {
        this.accept = this.acceptImg;
      } else if (this.mode == "视频") {
        this.accept = this.acceptVideo;
      }
    },
    changePage(v) {
      this.searchForm.pageNumber = v;
      this.getDataList();
    },
    getDataList() {
      this.dataLoading = true;
      if (this.mode == "视频") {
        this.searchForm.type = "video";
      }
      getUserFileListData(this.searchForm).then(res => {
        this.dataLoading = false;
        if (res.success) {
          this.data = res.result.content;
          this.data.forEach(e => {
            e.newName = e.title;
            e.selected = false;
            e.border = false;
          });
          this.total = res.result.totalElements;
          if (this.data.length == 0 && this.searchForm.pageNumber > 1) {
            this.searchForm.pageNumber -= 1;
            this.getDataList();
          }
        }
      });
    },
    clearSelectAll() {
      this.selectList = [];
    },
    changeSelect(v) {
      v.selected = !v.selected;
      if (this.multiple) {
        if (v.selected) {
          this.selectList.push(v);
        } else {
          this.selectList = this.selectList.filter(e => {
            return e.id != v.id;
          });
        }
      } else {
        if (v.selected) {
          if (this.selectList.length > 0) {
            this.selectList[0].selected = false;
            this.selectList = [];
          }
          this.selectList.push(v);
        } else {
          this.selectList = [];
        }
      }
    },
    handelSubmit() {
      let url = [];
      if (!this.urlMode) {
        this.selectList.forEach(e => {
          url.push(e.url);
        });
        if (this.multiple) {
          this.$emit("on-change", url);
        } else {
          this.$emit("on-change", url[0]);
        }
        this.modalVisible = false;
      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            url.push(this.form.url);
            if (this.multiple) {
              this.$emit("on-change", url);
            } else {
              this.$emit("on-change", url[0]);
            }
            this.modalVisible = false;
          }
        });
      }
    },
    preview(v) {
      if (this.mode == "图片") {
        this.viewImage(v);
      } else {
        this.showVideo(v);
      }
    },
    viewImage(v) {
      let image = new Image();
      image.src = v.url;
      let viewer = new Viewer(image, {
        hidden: function () {
          viewer.destroy();
        }
      });
      viewer.show();
    },
    showVideo(v) {
      this.dp = new DPlayer({
        container: document.getElementById(this.videoId),
        screenshot: true,
        video: {
          url: v.url
        }
      });
      this.videoTitle = v.title;
      this.videoVisible = true;
    },
    closeVideo() {
      if (this.dp) {
        this.dp.destroy();
      }
    },
    collect(v) {
      collectFile({
        id: v.id
      }).then(res => {
        this.$Modal.remove();
        if (res.success) {
          this.$Message.success(v.isCollect ? "取消收藏成功" : "收藏成功");
          this.getDataList();
        }
      });
    },
    onCopy() {
      this.$Message.success("复制链接成功");
    },
    onError() {
      this.$Message.warning("复制链接失败，请手动复制");
    },
    editName(v) {
      renameUserFile(v).then(res => {
        if (res.success) {
          this.$Message.success("操作成功");
          this.getDataList();
        }
      });
    },
    del(v) {
      trashFile({
        ids: v.id
      }).then(res => {
        if (res.success) {
          this.$Message.success("删除成功");
          this.getDataList();
        }
      });
    },
    handleFormatError(file) {
      this.loading = false;
      this.$Notice.warning({
        title: "不支持的文件格式",
        desc: "所选文件‘ " + file.name + " ’格式不正确, 请选择 " + this.accept + " 格式文件"
      });
    },
    handleMaxSize(file) {
      this.loading = false;
      this.$Notice.warning({
        title: "文件大小过大",
        desc: "所选文件‘ " + file.name + " ’大小过大, 不得超过 " + this.maxSize + "M."
      });
    },
    beforeUpload() {
      this.loading = true;
      return true;
    },
    handleSuccess(res, file) {
      this.loading = false;
      if (res.success) {
        this.$Message.success("上传成功");
        this.getDataList();
      } else {
        this.$Message.error(res.message);
      }
    },
    handleError(error, file, fileList) {
      this.loading = false;
      this.$Message.error(error.toString());
    },
    setCurrentValue(value) {
      if (value === this.modalVisible) {
        return;
      }
      this.modalVisible = value;
    }
  },
  watch: {
    mode(val) {
      this.changeAccept();
    },
    value(val) {
      this.setCurrentValue(val);
    },
    modalVisible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.init();
  }
};