var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Modal', {
    attrs: {
      "title": `我的${_vm.mode}`,
      "width": 904
    },
    model: {
      value: _vm.modalVisible,
      callback: function ($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.urlMode,
      expression: "!urlMode"
    }]
  }, [_c('div', {
    staticClass: "upload-nav"
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm.showUpload ? _c('Upload', {
    attrs: {
      "action": _vm.uploadFileUrl,
      "headers": _vm.accessToken,
      "data": _vm.uploadData,
      "on-success": _vm.handleSuccess,
      "on-error": _vm.handleError,
      "format": _vm.format,
      "accept": _vm.accept,
      "max-size": _vm.maxSize * 1024,
      "on-format-error": _vm.handleFormatError,
      "on-exceeded-size": _vm.handleMaxSize,
      "before-upload": _vm.beforeUpload,
      "show-upload-list": false
    }
  }, [_c('Button', {
    attrs: {
      "loading": _vm.loading,
      "icon": _vm.icon,
      "disabled": !_vm.searchForm.categoryId
    }
  }, [_vm._v("上传" + _vm._s(_vm.mode))])], 1) : _vm._e(), _vm.showInput ? _c('Button', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "icon": "ios-image-outline",
      "disabled": !_vm.searchForm.categoryId
    },
    on: {
      "click": function ($event) {
        _vm.urlMode = true;
      }
    }
  }, [_vm._v("网络" + _vm._s(_vm.mode))]) : _vm._e(), _c('Input', {
    staticStyle: {
      "width": "230px",
      "margin-left": "5px"
    },
    attrs: {
      "suffix": "ios-search",
      "placeholder": "输入文件名搜索",
      "clearable": ""
    },
    on: {
      "on-change": _vm.getDataList
    },
    model: {
      value: _vm.searchForm.title,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "title", $$v);
      },
      expression: "searchForm.title"
    }
  })], 1), _c('Button', {
    attrs: {
      "icon": "md-refresh"
    },
    on: {
      "click": _vm.getDataList
    }
  }, [_vm._v("刷新")])], 1), _c('div', {
    staticClass: "content-wrap"
  }, [_c('div', {
    staticClass: "category"
  }, [_c('fileCategory', {
    attrs: {
      "showOnly": ""
    },
    on: {
      "on-select": _vm.selectCategory
    }
  })], 1), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total == 0,
      expression: "total == 0"
    }],
    staticClass: "empty"
  }, [_vm._v(" 暂无数据，点击左上角上传按钮添加 ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    staticClass: "wrap"
  }, [_vm._l(_vm.data, function (item, index) {
    return _c('div', {
      key: index,
      class: {
        item: !item.selected,
        'item active': item.selected
      },
      style: {
        marginRight: (index + 1) % 5 == 0 ? '0px' : '10px',
        marginBottom: index < 5 ? '20px' : '0px'
      }
    }, [_c('div', {
      staticClass: "preview",
      on: {
        "click": function ($event) {
          return _vm.changeSelect(item);
        }
      }
    }, [item.isCollect ? _c('div', {
      staticClass: "collect-icon"
    }, [_c('Icon', {
      attrs: {
        "type": "md-star",
        "color": "#ffc60a",
        "size": "16"
      }
    })], 1) : _vm._e(), _vm.mode == '图片' ? _c('img', {
      staticClass: "img",
      attrs: {
        "src": item.url
      }
    }) : _c('div', {
      staticClass: "video"
    }, [_c('video', {
      staticClass: "cover"
    }, [_c('source', {
      attrs: {
        "src": item.url + '#t=1',
        "preload": "metadata"
      }
    })]), _c('img', {
      staticClass: "play",
      attrs: {
        "src": require("@/assets/icon/play.png")
      }
    })]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.selected,
        expression: "item.selected"
      }],
      staticClass: "border"
    }), _c('div', {
      staticClass: "border-hover"
    }), _c('img', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.selected,
        expression: "item.selected"
      }],
      staticClass: "select",
      attrs: {
        "src": require("@/assets/icon/select.png")
      }
    })]), _c('Tooltip', {
      attrs: {
        "content": `${item.title} ${_vm.util.getFileSize(item.size)}`,
        "placement": "top",
        "max-width": 500
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.title))])]), _vm.showMenus ? _c('div', {
      staticClass: "ops"
    }, [_c('a', {
      on: {
        "click": function ($event) {
          return _vm.preview(item);
        }
      }
    }, [_vm._v("预览")]), _c('Divider', {
      attrs: {
        "type": "vertical"
      }
    }), _vm.readonly ? _c('a', {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: item.url,
        expression: "item.url",
        arg: "copy"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: _vm.onCopy,
        expression: "onCopy",
        arg: "success"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:error",
        value: _vm.onError,
        expression: "onError",
        arg: "error"
      }]
    }, [_vm._v(" 复制链接 ")]) : _vm._e(), !_vm.readonly ? _c('Poptip', {
      attrs: {
        "width": "350"
      }
    }, [_c('a', [_vm._v("编辑")]), _c('div', {
      staticClass: "edit",
      attrs: {
        "slot": "content"
      },
      slot: "content"
    }, [_vm._v(" 文件名： "), _c('Input', {
      staticStyle: {
        "width": "280px"
      },
      attrs: {
        "search": "",
        "enter-button": "提交"
      },
      on: {
        "on-search": function ($event) {
          return _vm.editName(item);
        }
      },
      model: {
        value: item.newName,
        callback: function ($$v) {
          _vm.$set(item, "newName", $$v);
        },
        expression: "item.newName"
      }
    })], 1)]) : _vm._e(), !_vm.readonly ? _c('Divider', {
      attrs: {
        "type": "vertical"
      }
    }) : _vm._e(), !_vm.readonly ? _c('Dropdown', [_c('a', [_vm._v("更多")]), _c('DropdownMenu', {
      staticClass: "block-pop",
      attrs: {
        "slot": "list"
      },
      slot: "list"
    }, [_c('div', {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: item.url,
        expression: "item.url",
        arg: "copy"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: _vm.onCopy,
        expression: "onCopy",
        arg: "success"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:error",
        value: _vm.onError,
        expression: "onError",
        arg: "error"
      }],
      staticClass: "ivu-dropdown-item"
    }, [_vm._v(" 复制链接 ")]), _c('div', {
      staticClass: "ivu-dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.collect(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.isCollect ? "取消收藏" : "收藏") + " ")]), _c('Poptip', {
      attrs: {
        "confirm": "",
        "title": "确认将此素材移动至回收站？"
      },
      on: {
        "on-ok": function ($event) {
          return _vm.del(item);
        }
      }
    }, [_c('div', {
      staticClass: "ivu-dropdown-item"
    }, [_vm._v("删除")])])], 1)], 1) : _vm._e()], 1) : _vm._e()], 1);
  }), _c('Spin', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dataLoading,
      expression: "dataLoading"
    }],
    attrs: {
      "fix": ""
    }
  })], 2), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "select-count"
  }, [_vm._v(" " + _vm._s(_vm.selectList.length > 0 ? `已选 ${_vm.selectList.length} 项` : "") + " ")]), _c('Page', {
    attrs: {
      "current": _vm.searchForm.pageNumber,
      "total": _vm.total,
      "page-size": _vm.searchForm.pageSize,
      "size": "small",
      "show-total": "",
      "show-elevator": ""
    },
    on: {
      "on-change": _vm.changePage
    }
  })], 1)])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.urlMode,
      expression: "urlMode"
    }]
  }, [_c('div', {
    staticClass: "upload-nav"
  }, [_c('Button', {
    attrs: {
      "icon": "ios-arrow-back"
    },
    on: {
      "click": function ($event) {
        _vm.urlMode = false;
      }
    }
  }, [_vm._v("返回")])], 1), _c('div', {
    staticStyle: {
      "width": "100%",
      "display": "flex"
    }
  }, [_c('Form', {
    ref: "form",
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "model": _vm.form,
      "label-position": "left",
      "label-width": 100,
      "rules": _vm.ruleValidate
    }
  }, [_c('FormItem', {
    attrs: {
      "label": `${_vm.mode}链接`,
      "prop": "url"
    }
  }, [_c('Input', {
    style: `width: ${_vm.mode == '图片' ? '600px' : '720px'}`,
    attrs: {
      "type": "textarea",
      "rows": 5,
      "maxlength": _vm.maxlength,
      "show-word-limit": "",
      "placeholder": `请输入${_vm.mode}链接...`
    },
    model: {
      value: _vm.form.url,
      callback: function ($$v) {
        _vm.$set(_vm.form, "url", $$v);
      },
      expression: "form.url"
    }
  })], 1)], 1), _vm.mode == '图片' ? _c('div', [!_vm.form.url ? _c('Avatar', {
    attrs: {
      "shape": "square",
      "icon": "md-images",
      "size": "110"
    }
  }) : _c('Avatar', {
    attrs: {
      "shape": "square",
      "src": _vm.form.url,
      "size": "110"
    }
  })], 1) : _vm._e()], 1)]), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        _vm.modalVisible = false;
      }
    }
  }, [_vm._v("取消")]), _c('Button', {
    attrs: {
      "disabled": _vm.selectList.length == 0 && !_vm.urlMode || !_vm.form.url && _vm.urlMode,
      "type": "primary"
    },
    on: {
      "click": _vm.handelSubmit
    }
  }, [_vm._v("确定")])], 1)]), _vm.mode == '视频' ? _c('Modal', {
    attrs: {
      "title": _vm.videoTitle,
      "width": 800,
      "draggable": "",
      "footer-hide": ""
    },
    on: {
      "on-cancel": _vm.closeVideo
    },
    model: {
      value: _vm.videoVisible,
      callback: function ($$v) {
        _vm.videoVisible = $$v;
      },
      expression: "videoVisible"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "id": _vm.videoId
    }
  })]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };